import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

// FIXME: Пытался кастомны джсом но не мог зацепиться, пока так но убрать конечно все что связано с хиро в ближайшем будущем
const isHero =
  document.location.hostname === 'portal.herofx.co' || document.location.hostname === 'herodev.prod.uptr.dev';

const ChallengePageChart = ({ chart = {}, challenge }) => {
  const series = [
    {
      name: 'Current Balance',
      data: Object.keys(chart).map(key => ({
        x: new Date(key).getTime(),
        y: chart[key],
      })),
    },
  ];

  const drawDownLimit = useMemo(() => {
    const leftForMaxDailyLoss = +challenge.dailyProfit.amount - +challenge.level.maximum_daily_loss.amount;

    const leftForMaxLoss =
      +challenge.challenge.initialAccountBalance.amount - +challenge.level.maximum_total_loss.amount;

    if (leftForMaxDailyLoss >= leftForMaxLoss) {
      return +challenge.equity.amount - leftForMaxDailyLoss;
    }

    return leftForMaxLoss;
  }, [challenge]);

  const options = {
    chart: {
      height: 350,
      type: 'line',
      id: 'areachart-2',
    },
    annotations: {
      yaxis: [
        ...(isHero
          ? []
          : [
              {
                y: challenge.profitTarget.amount,
                borderColor: '#00E396',
                label: {
                  borderColor: '#00E396',
                  style: {
                    color: '#fff',
                    background: '#00E396',
                  },
                  text: 'Profit Target',
                },
              },
            ]),
        {
          y: drawDownLimit,
          borderColor: '#FF4560',
          label: {
            borderColor: '#FF4560',
            style: {
              color: '#fff',
              background: '#FF4560',
            },
            text: 'Drawdown Limit',
          },
        },
      ],
      xaxis: [
        ...(+challenge.level.minimum_trading_days > 0
          ? [
              {
                x: new Date(challenge.created).getTime() + challenge.level.minimum_trading_days * 86400000,
                strokeDashArray: 0,
                borderColor: '#0AA1E2',
                label: {
                  borderColor: '#0AA1E2',
                  style: {
                    color: '#fff',
                    background: '#0AA1E2',
                  },
                  text: 'Minimum trading days',
                },
              },
            ]
          : []),
        ...(+challenge.level.maximum_trading_days
          ? [
              {
                x: new Date(challenge.created).getTime() + challenge.level.maximum_trading_days * 86400000,
                strokeDashArray: 0,
                borderColor: 'red',
                label: {
                  borderColor: 'red',
                  style: {
                    color: '#fff',
                    background: 'red',
                  },
                  text: 'Maximum trading days',
                },
              },
            ]
          : []),
      ],
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    title: {
      text: 'Level statistic',
      align: 'left',
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      min(min) {
        return min - 50;
      },
      max(max) {
        return max + 100;
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="line" height={350} />
      </div>
      <div id="html-dist" />
    </div>
  );
};

export default ChallengePageChart;
