import { DefaultButton, RadioButton, ReduxSelectField } from 'common/components';
import { FormMessageHOC } from 'common/HOCs';
import React, { useMemo } from 'react';
import { FormattedMessage as FM, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';

import { getTradingAccountList } from '../../../../../_redux/actions';

import { activateChallenge } from '../../../../_redux/actions';

import { staticStyles } from './style';

// FIXME: Пытался кастомны джсом но не мог зацепиться, пока так но убрать конечно все что связано с хиро в ближайшем будущем Этот компонент Можно в целом оставить

const StartChallengeFormHERO10X = ({
  typeValue,
  budgetValue,
  challenges,
  handleSubmit,
  submitting,
  initialize,
  invalid,
  dispatch,
  pristine,
  error,
  intl,
}) => {
  const typeOptions = challenges.map(challengeType => ({ label: challengeType.title, value: challengeType.id }));
  let budgetOptions = [];
  const selectedChallengeType = challenges.find(({ id }) => id === typeValue);
  if (selectedChallengeType) {
    budgetOptions = selectedChallengeType.challenges.map(challenge => ({
      label: `${intl.formatNumber(challenge.initialAccountBalance.amount)} ${challenge.initialAccountBalance.currency}`,
      value: challenge.id,
    }));
  }

  const submit = handleSubmit(() => {
    activateChallenge(
      {
        id: budgetValue,
        account: undefined,
      },
      dispatch
    );
  });

  const selectedChallengeData = useMemo(() => {
    if (typeof budgetValue === 'number') {
      const selectedGroup = challenges.find(({ id }) => id === typeValue);

      if (!selectedGroup) return null;

      return selectedGroup.challenges.find(({ id }) => id === budgetValue);
    }
    return null;
  }, [budgetValue, typeValue, challenges]);

  return (
    <form className="StartChallengeFormHERO10X" onSubmit={submit}>
      <FM id="justStartChallengeFormTopTextHERO10X">
        {txt => <span className="StartChallengeFormHERO10X__TopText challenge-text">{txt}</span>}
      </FM>
      <Field
        className="challenge-field"
        component={ReduxSelectField}
        name="type"
        maxMenuHeight={140}
        textId="justAccountType"
        options={typeOptions}
        validate={[required()]}
        onChange={() => {
          initialize({ type: typeValue, budget: null });
        }}
        disabled={submitting}
        searchable
      />
      <Field
        component={ReduxSelectField}
        name="budget"
        maxMenuHeight={140}
        className="challenge-field"
        textId="justInitialBalance"
        options={budgetOptions.sort((a, b) => a.label.localeCompare(b.label))}
        validate={[required()]}
        disabled={submitting}
        searchable
      />
      <div
        className="StartChallengeFormHERO10X__PaymentMethod"
        style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '25px' }}>
        <FM id="justPaymentMethodForChallengeHERO10X">{txt => <span className="challenge-text">{txt}:</span>}</FM>

        <RadioButton name="paymentMethod" textId="justWithWallet" value="wallet" onChange={() => {}} checked />
      </div>
      <div style={{ marginTop: '20px' }} className="StartChallengeFormHERO10X__HowDoesWork__Container">
        <span className="StartChallengeFormHERO10X__HowDoesWork__Text">How does the Hero10X account work?</span>
        <table width="100%">
          <tr>
            <td className="firstChild">Trading Access</td>
            <td>
              <FM id="justInstantlyHERO10X">{txt => txt}</FM>
            </td>
          </tr>
          <tr>
            <td className="firstChild">Profit Withdrawals</td>
            <td>Withdraw 24/7</td>
          </tr>
          <tr>
            <td className="firstChild">Profit Share</td>
            <td>Keep 100% of your Profits</td>
          </tr>
          <tr>
            <td className="firstChild">Drawdown Rule</td>
            <td>10% Fixed Drawdown on Initial Balance</td>
          </tr>
          <tr>
            <td className="firstChild">Other Rules</td>
            <td>No hidden rules, full trading freedom</td>
          </tr>
        </table>
      </div>

      <div className="StartChallengeFormHERO10X__bottom">
        <div className="StartChallengeFormHERO10X__RefundableFee">
          <FM id="justPrice">{txt => <span>{txt}:</span>}</FM>
          <div>{selectedChallengeData?.cost ?? '---'}</div>
        </div>

        <div className="DivWithDefButton">
          <DefaultButton
            className="challenge-button"
            textId="justStartChallengeHERO10X"
            type="submit"
            disabled={invalid || pristine || submitting}
            loading={submitting}
            filled
          />
        </div>
      </div>

      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

const selector = formValueSelector('startChallengeFormHERO10X');
export default compose(
  FormMessageHOC,
  injectIntl,
  connect(
    state => {
      const { type: typeValue, budget: budgetValue } = selector(state, 'type', 'budget');
      return {
        typeValue,
        budgetValue,
      };
    },
    { getTradingAccountList: () => getTradingAccountList.request() }
  ),
  reduxForm({
    form: 'startChallengeFormHERO10X',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
    enableReinitialize: true,
  })
)(StartChallengeFormHERO10X);
